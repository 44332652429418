import Vue from 'vue';
import { Link } from '../../types/link';
import { UtilityNavProps } from './UtilityNav.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'UtilityNav',
	props: UtilityNavProps,
	computed: {
		storesCta() {
			return {
				uid: 'UTILITYNAV_STORES',
				url: this.storesUrl,
			};
		},
	},
	methods: {
		handleLanguageChange(language: Link) {
			this.$emit('language-change', language);
		},
	},
});
