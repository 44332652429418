import { render, staticRenderFns } from "./UtilityNav.vue?vue&type=template&id=4435d443&"
import script from "./UtilityNav.ts?vue&type=script&lang=ts&"
export * from "./UtilityNav.ts?vue&type=script&lang=ts&"
import style0 from "./UtilityNav.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CallCenterCta: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/CallCenterCta/CallCenterCta.vue').default,StoresCta: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/StoresCta/StoresCta.vue').default,LoginCta: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/LoginCta/LoginCta.vue').default,ClubCta: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/ClubCta/ClubCta.vue').default,LanguageSwitcher: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/LanguageSwitcher/LanguageSwitcher.vue').default})
