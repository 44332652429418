import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Link } from '../../types/link';

// Model -------------------------------------------------------------------------------------------

export interface LanguageSwitcherModel {
	uid: string;
	value: Link;
	languages: Link[];
}

// Props -------------------------------------------------------------------------------------------

export const LanguageSwitcherProps: Props<LanguageSwitcherModel> = {
	languages: {
		type: Array as PropType<LanguageSwitcherModel['languages']>,
		required: true,
	},
	uid: {
		type: String as PropType<LanguageSwitcherModel['uid']>,
		required: true,
	},
	value: {
		type: Object as PropType<LanguageSwitcherModel['value']>,
		required: true,
	},
};
