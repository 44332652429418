import Vue from 'vue';
import { setSafeTimeout } from '../../assets/js/utilities/timeout';
import { InlineSvgModel } from '../InlineSvg/InlineSvg.props';
import { ClubCtaProps } from './ClubCta.props';

// Constant(s) -------------------------------------------------------------------------------------

const COOKIE_EXPIRE_YEARS = 10;
const COOKIE_SET_DELAY = 2500;

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'ClubCta',
	props: ClubCtaProps,
	data() {
		return {
			hidden: this.hasBonus && this.$cookies.get(this.cookieName),
			cookieTimeout: NaN,
		};
	},
	computed: {
		inlineSvgData(): InlineSvgModel {
			return {
				uid: 'CLUB_CTA_INLINESVG',
				name: 'icons/logo__osp--multiline',
			};
		},
	},
	mounted() {
		if (!this.hidden) {
			this.cookieTimeout = setSafeTimeout(this.setCookie, COOKIE_SET_DELAY) ?? NaN;
		}
	},
	beforeDestroy() {
		if (this.cookieTimeout) {
			clearTimeout(this.cookieTimeout);
		}
	},
	methods: {
		setCookie() {
			const expires = new Date();

			expires.setFullYear(expires.getFullYear() + COOKIE_EXPIRE_YEARS);
			this.$cookies.set(this.cookieName, true, { expires, path: '/' });
		},
		handleButtonClick() {
			if (this.$route.path === this.href) return;

			this.handleCloseButtonClick();
			this.$router.push(this.href);

			if (!this.tracking) return;

			this.tracking.click?.();
		},
		handleCloseButtonClick() {
			this.hidden = true;
		},
	},
});
