import { PropType } from 'vue';
import { Props } from '../../types/core';
import { TrackingEvents } from '../../types/tracking';

// Model -------------------------------------------------------------------------------------------

export interface ClubCtaModel {
	uid: string;
	text: string;
	href: string;
	cookieName: string;
	hasBonus?: boolean;
	tracking?: TrackingEvents;
}

// Props -------------------------------------------------------------------------------------------

export const ClubCtaProps: Props<ClubCtaModel> = {
	uid: {
		type: String as PropType<ClubCtaModel['uid']>,
		required: true,
	},
	text: {
		type: String as PropType<ClubCtaModel['text']>,
		required: true,
	},
	href: {
		type: String as PropType<ClubCtaModel['href']>,
		required: true,
	},
	cookieName: {
		type: String as PropType<ClubCtaModel['cookieName']>,
		required: true,
	},
	hasBonus: {
		type: Boolean as PropType<ClubCtaModel['hasBonus']>,
		default: false,
	},
	tracking: {
		type: Object as PropType<ClubCtaModel['tracking']>,
		default: null,
	},
};
