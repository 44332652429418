import { PropType } from 'vue';
import { Props } from '../../types/core';
import { CallCenterCtaModel } from '../CallCenterCta/CallCenterCta.props';
import { LoginCtaModel } from '../LoginCta/LoginCta.props';
import { ClubCtaModel } from '../ClubCta/ClubCta.props';
import { LanguageSwitcherModel } from '../LanguageSwitcher/LanguageSwitcher.props';

// Model -------------------------------------------------------------------------------------------

export interface UtilityNavModel {
	uid: string;
	callCenterCta: CallCenterCtaModel;
	storesUrl: string;
	loginCta: LoginCtaModel;
	clubCta: ClubCtaModel;
	languageSwitcher: LanguageSwitcherModel;
}

// Props -------------------------------------------------------------------------------------------

export const UtilityNavProps: Props<UtilityNavModel> = {
	uid: {
		type: String as PropType<UtilityNavModel['uid']>,
		required: true,
	},
	callCenterCta: {
		type: Object as PropType<UtilityNavModel['callCenterCta']>,
		required: true,
	},
	storesUrl: {
		type: String as PropType<UtilityNavModel['storesUrl']>,
		required: true,
	},
	loginCta: {
		type: Object as PropType<UtilityNavModel['loginCta']>,
		required: true,
	},
	clubCta: {
		type: Object as PropType<UtilityNavModel['clubCta']>,
		required: true,
	},
	languageSwitcher: {
		type: Object as PropType<UtilityNavModel['languageSwitcher']>,
		required: true,
	},
};
