import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface StoresCtaModel {
	uid: string;
	url: string;
}

// Props -------------------------------------------------------------------------------------------

export const StoresCtaProps: Props<StoresCtaModel> = {
	uid: {
		type: String as PropType<StoresCtaModel['uid']>,
		required: true,
	},
	url: {
		type: String as PropType<StoresCtaModel['url']>,
		required: true,
	},
};
