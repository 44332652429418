import Vue from 'vue';
import { Link } from '../../types/link';
import { LanguageSwitcherProps } from './LanguageSwitcher.props';

// Constants(s) ------------------------------------------------------------------------------------

const ICON = {
	OPEN: 'arrow-up-small',
	CLOSED: 'arrow-down-small',
};

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'LanguageSwitcher',
	model: { prop: 'value', event: 'change' },
	props: LanguageSwitcherProps,
	data() {
		return {
			isOpen: false,
		};
	},
	computed: {
		unselectedLanguages(): Link[] {
			return this.languages.filter((language) => language.text !== this.value?.text);
		},
		icon(): string {
			return this.isOpen ? ICON.OPEN : ICON.CLOSED;
		},
	},
	methods: {
		closeList(): void {
			this.isOpen = false;
		},
		choose(language: Link): void {
			this.$emit('change', language);
			this.closeList();
		},
		toggleList(): void {
			this.isOpen = !this.isOpen;
		},
	},
});
