import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface CallCenterCtaModel {
	text: string;
	url: string;
	isAgent: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const CallCenterCtaProps: Props<CallCenterCtaModel> = {
	text: {
		type: String as PropType<CallCenterCtaModel['text']>,
		required: true,
	},
	url: {
		type: String as PropType<CallCenterCtaModel['url']>,
		required: true,
	},
	isAgent: {
		type: Boolean as PropType<CallCenterCtaModel['isAgent']>,
		required: true,
	},
};
